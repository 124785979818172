.App {
  text-align: center;
}

.navbarStyle {
  padding: 0.5rem 1rem !important;
}

.green{
  color: darkgreen;
}

.large-text{
  max-width: 15em;
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
}
.width-all-screen{
  width:100%; 
  table-layout: fixed;
  overflow-wrap: break-word;
}

.tiny-font{
  font-size: 13px;
}

.min-h{
  min-height: 300px;
}