a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.roboto-15-500-2{
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; 
}

.primary-nav-button{
  border-radius: 20px;
  color: white;
  display: flex;
  /* min-width: 85px; */
  height: 36px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: var(--daikin-primary-daikin-blue, #0097E0);
  border: 1px solid var(--daikin-primary-daikin-blue, #0097E0);
  fill: white;
}

.secondary-nav-button{
  border-radius: 20px;
  color: var(--vrv-dark-gray);
  display: flex;
  /* min-width: 85px; */
  width: flex;
  height: 36px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: 1px solid transparent;
  background-color: white;
  fill: var(--color-brand-primary, #0097E0);
}

.primary-nav-button:hover,
.secondary-nav-button:hover{
  color: var(--vrv-dark-gray);
  border: 1px solid transparent;
  background: var(--blue-base-color-blue-010, #D1F3FF);
  fill: var(--color-brand-primary, #0097E0);
}


.primary-nav-button:active,
.secondary-nav-button:active{
  color: var(--vrv-dark-gray);
  border-radius: 20px;
  border: 1px solid var(--color-brand-primary, #0097E0);
  background: var(--blue-base-color-blue-010, #D1F3FF);
  fill: var(--color-brand-primary, #0097E0);
}

.primary-link,
.primary-link:focus,
.secondary-link:focus{
  color: white;
}

.secondary-link,
.primary-link:active,
.secondary-link:active,
.primary-link:hover,
.secondary-link:hover{
  color: black;
}

.box-shadow {
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.16);
}

.custom-navbar {
  min-height: 40px;
  padding: 10px 20px 8px 20px; 
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
  background-color: #f8f9fa;
}

.navContainer_home span {
  margin-left: 30px;
  padding-left: 15px;
  border-left: solid 2px var(--daikin-primary-daikin-blue);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  color: var(--daikin-primary-daikin-blue);
  text-decoration: none;
}

/* Agrega esto a tu archivo NavMenu.css o crea un nuevo archivo Sidebar.css */
.sidebar {
  transition: all 0.3s ease;
  z-index: 40;
}

.sidebar-link {
  color: var(--vrv-dark-gray);
  transition: all 0.2s ease;
}

.sidebar-link:hover {
  background-color: var(--blue-base-color-blue-010, #D1F3FF);
  color: var(--daikin-primary-daikin-blue, #0097E0);
}

.sidebar-section-title {
  color: var(--vrv-dark-gray);
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.buttonPrimary {
  border-left-width: none;
  border-left-color: #0097E0;
  border-top-width: none;
  border-top-color: #0097E0;
  border-right-width: none;
  border-right-color: #0097E0;
  border-bottom-width: none;
  border-bottom-color: #0097E0;
  color: #FFFFFF;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #54C3F1;
}

.buttonPrimary:hover {
  background: #0078B3;
}

.popup-content {
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  margin-top: 8px;
}
.nav-user-menu {
  display: flex;
  align-items: center;
  gap: 8px;
}

.nav-logout-button {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  text-decoration: none;
  color: inherit;
}

.nav-logout-button:hover {
  background: #f5f5f5;
  border-radius: 4px;
}