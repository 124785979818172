/* src/components/Sidebar/Sidebar.css */
.sidebar-nav {
  width: 280px;
  height: 100%;
  padding: 0.5rem 1rem;
  overflow-y: auto;
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
}

.sidebar-section {
  margin-bottom: 0.2rem;
}

.sidebar-section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  color: #6A737C;
  font-size: 0.8rem;
  font-weight: normal;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.sidebar-section-title:hover {
  background-color: #eef2f7;
}

.sidebar-section-title.active {
  background-color: #F0F2C6;
}

.sidebar-section-title a {
  text-decoration: none;
  color: inherit;
  flex: 1;
  display: flex;
  align-items: center;
}

.sidebar-section-title a.active-link {
  color: #0097E0;
  font-weight: bold;
}

.sidebar-children {
  margin-left: 1rem;
  padding-left: 0.5rem;
  border-left: 1px solid #e0e0e0;
}

.nav-level-1 {
  margin-left: 0;
}

.nav-level-2 {
  margin-left: 0.5rem;
}

.nav-level-3 {
  margin-left: 1rem;
}

/* Scrollbar styles */
.sidebar-nav::-webkit-scrollbar {
  width: 3px;
}

.sidebar-nav::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar-nav::-webkit-scrollbar-thumb {
  background: #cfd4d9;
  border-radius: 3px;
}